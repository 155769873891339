/**
 * Takes a value and returns a Date instance
 */
export function toDate(value, fallback) {
  if (value instanceof Date) {
    return value
  }

  if (typeof value === 'string') {
    switch (value) {
      case 'now':
        return new Date()
      case 'today': {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        return date
      }
      default:
        return new Date(value)
    }
  }

  return toDate(fallback, 'now')
}

/**
 * Takes a value and returns a Date instance where the time has been reset to zero
 */
export function toDay(value, fallback) {
  const day = new Date(toDate(value, fallback))
  day.setHours(0, 0, 0, 0)
  return day
}

/**
 * Takes a value and returns a Date instance where the time has been rounded to an hour
 */
export function toHour(value, fallback) {
  const hour = new Date(toDate(value, fallback))
  hour.setMinutes(0, 0, 0)
  return hour
}

/**
 * Takes two values and returns the largest as a Date instance
 */
export function toSmallest(a, b, fallback) {
  const a_ = toDate(a, fallback)
  const b_ = toDate(b, fallback)
  return a_ < b_ ? a_ : b_
}

/**
 * Takes two values and returns the largest as a Date instance
 */
export function toLargest(a, b, fallback) {
  const a_ = toDate(a, fallback)
  const b_ = toDate(b, fallback)
  return a_ < b_ ? b_ : a_
}

export function isBetween(value, a, b, fallback = false) {
  if (!value || !a || !b) {
    return fallback
  }
  const date = toDate(value)
  return toSmallest(a, b) <= date && date <= toLargest(a, b)
}

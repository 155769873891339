
import { defineComponent, onMounted, onUnmounted, ref, unref, useStore, useContext } from '@nuxtjs/composition-api'
import { setupTix } from '~/assets/composition/tix'

export default defineComponent({
  setup() {
    const { tixOrigin, tixIframeUrl } = setupTix()
    const store = useStore()
    const tixIframe = ref(null)
    const { $bridgeFetch } = useContext()

    const listener = async (message) => {
      if (message.origin !== tixOrigin) {
        // Ignore non-tix messages
        return
      }
      store.commit('tix/update', message.data)
      store.commit('favorites/setUser', message.data?.user)
      if (message.data?.user?.id && message.data?.user?.hash) {
        store.commit('tix/setCustomerId', await $bridgeFetch(`/api/tix/customer/${message.data?.user?.id}?hash=${message.data?.user?.hash}`, { method: 'GET' }))
      }
    }

    const onLoad = (event) => {
      if (!unref(tixIframe)) {
        // eslint-disable-next-line no-console
        console.error('Tix - Abort state retrieval')
        return
      }

      // Request Tix status
      unref(tixIframe).contentWindow.postMessage('GetSession', tixOrigin)
    }

    // Make sure that the event listener is also removed, otherwise they will accumulate
    onMounted(() => window.addEventListener('message', listener))
    onUnmounted(() => window.removeEventListener('message', listener))

    return { tixIframe, tixIframeUrl, onLoad }
  },
})


import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { computed, defineComponent } from '@nuxtjs/composition-api'

const validIcons = [
  'app-app',
  'app-archive',
  'app-bullhorn',
  'app-checkbox-checked',
  'app-checkbox-unchecked',
  'app-circled-exclamation-mark',
  'app-digital',
  'app-education',
  'app-external',
  'app-filter',
  'app-hat',
  'app-loader',
  'app-lock',
  'app-pause',
  'app-person',
  'app-play',
  'app-play-triangle',
  'app-search',
  'app-signpost',
  'app-speaker',
  'app-speaker-muted',
  'app-websitevanhetjaar-dark',
  'app-websitevanhetjaar-light',
  'app-youtube',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'basket',
  'bell',
  'birthday-cake',
  'calendar',
  'camera',
  'cart',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circled-check',
  'circled-facebook',
  'circled-instagram',
  'circled-spotify',
  'circled-twitter',
  'circled-youtube',
  'facebook-f',
  'gear',
  'hamburger',
  'heart',
  'heart-filled',
  'instagram',
  'mail',
  'microphone',
  'minus',
  'music-note',
  'pdf-file',
  'pile',
  'phone',
  'piggy-bank',
  'plus',
  'search',
  'twitter',
  'whats-app',
  'x',
]

export default defineComponent({
  props: {
    icon: { type: String, required: true, default: 'x', validator: (value) => validIcons.includes(value) },
    classes: { type: [String, Array, Object], required: false, default: undefined },
  },
  setup(props) {
    const componentName = computed(() => `AtomIcon${upperFirst(camelCase(props.icon))}`)
    return { componentName }
  },
})
